.Loading {
  background-color: transparent;
  border-radius: 100%;
  animation: rotate 1000ms infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
