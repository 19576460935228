/* DataTableDemo.css */
.datatable-filter-demo .p-paginator .p-paginator-current {
  margin-left: auto;
}
.datatable-filter-demo .p-progressbar {
  height: 0.5rem;
  background-color: #d8dadc;
}
.datatable-filter-demo .p-progressbar .p-progressbar-value {
  background-color: #607d8b;
}
.datatable-filter-demo .p-datepicker {
  min-width: 25rem;
}
.datatable-filter-demo .p-datepicker td {
  font-weight: 400;
}
.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-header {
  padding: 1rem;
  text-align: left;
  font-size: 1.5rem;
}
.datatable-filter-demo .p-datatable.p-datatable-customers .p-paginator {
  padding: 1rem;
}
.datatable-filter-demo
  .p-datatable.p-datatable-customers
  .p-datatable-thead
  > tr
  > th {
  text-align: left;
}

.datatable-filter-demo
  .p-datatable.p-datatable-customers
  .p-datatable-tbody
  > tr
  > td {
  cursor: auto;
}
.datatable-filter-demo
  .p-datatable.p-datatable-customers
  .p-dropdown-label:not(.p-placeholder) {
  text-transform: uppercase;
}
.p-datatable-loading-overlay.p-component-overlay {
  background-color: #fff;
  opacity: 0.35;
}
.Report .p-paginator.p-component.p-paginator-bottom {
  border: none;
  /* border-radius: 3px; */
}

.Report .p-paginator-next.p-paginator-element.p-link {
  border-radius: 8px;
  height: 40px;
}
.Report .p-paginator-prev.p-paginator-element.p-link {
  border-radius: 8px;
  height: 40px;
}

.dropdown-check .p-checkbox-box.p-highlight {
  background-color: #36B37E !important;
  border-color: #36B37E;
}
.dropdown-check .p-checkbox-box:hover {
  border-color: #36B37E !important;
}
.dropdown-check .p-multiselect-item.p-highlight {
  background-color: rgba(54, 179, 126, 0.1) !important;
  color:#36B37E !important;
}
.dropdown-check .p-multiselect-item.p-highlight:focus {
  border: none;
  box-shadow: inset 0 0 0 0.15rem rgba(54, 179, 126, 0.3);
}
.dropdown-check .p-checkbox-box.p-highlight {
  background-color: #36B37E !important;
  box-shadow: inset 0 0 0 0.15rem rgba(54, 179, 126, 0.3);
}
.dropdown-check .p-checkbox-box.p-highlight.p-focus {
  border-color: #36B37E !important;
  box-shadow: inset 0 0 0 0.15rem rgba(54, 179, 126, 0.3) !important;
}
.dropdown-check .p-checkbox-box.p-focus {
  border-color: #36B37E !important;
  box-shadow: inset 0 0 0 0.15rem rgba(54, 179, 126, 0.3) !important;
}

.p-multiselect.p-component.p-inputwrapper.p-focus.p-inputwrapper-focus.p-column-filter {
  border-color: #36B37E !important;
  box-shadow: inset 0 0 0 0.15rem rgba(54, 179, 126, 0.3) !important;
}
.p-multiselect.p-component.p-inputwrapper.p-inputwrapper.p-column-filter:hover {
  border-color: #36B37E !important;
}
.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled.p-focus.p-column-filter-matchmode-dropdown {
  border-color: #36B37E !important;
  box-shadow: inset 0 0 0 0.15rem rgba(54, 179, 126, 0.3) !important;
}
.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled.p-column-filter-matchmode-dropdown:hover {
  border-color: #36B37E !important;
}
.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled.p-focus.p-column-filter {
  border-color: #36B37E !important;
  box-shadow: inset 0 0 0 0.15rem rgba(54, 179, 126, 0.3) !important;
}
.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled.p-column-filter:hover {
  border-color: #36B37E !important;
}
.p-column-filter-menu-button.p-link:focus {
  background-color: rgba(54, 179, 126, 0.2) !important;
  border-color: #36B37E !important;
  box-shadow: inset 0 0 0 0.15rem rgba(54, 179, 126, 0.3) !important;
}
.p-column-filter-menu-button.p-link.p-column-filter-menu-button-active {
  background-color: rgba(54, 179, 126, 0.8) !important;
  color: #fff;
}

@media only screen and (max-height: 750px) {
  .Report {
    overflow-y: scroll;
    height: 80vh;
  }
}
