.p-inputgroup {
  border: 1px solid rgba(208, 215, 222, 1);
  border-radius: 8px;
}

.p-inputgroup:hover {
  border: 1px solid rgba(0, 101, 255, 1);
}

.active {
  border: 1px solid rgba(0, 101, 255, 1) !important;
}

.invalid {
  border: 1px solid #FF3030 !important;
}