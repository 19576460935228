.Dashboard.container-full {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: flex-start;
  justify-items: center;
  z-index: 0;
  overflow: scroll;
}

.Dashboard .container-datatable {
  width: 80vw;
  margin-top: 2rem;
  border-bottom-color: transparent;
  border-radius: 3px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
}

.quantity-registers-container {
  display: flex;
  align-content: center;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
}
.quantity-registers-container .DropDown {
  transform: scale(0.8);
}

.Dashboard .p-paginator.p-component.p-paginator-bottom {
  border: none;
  /* border-radius: 3px; */
}

.Dashboard .p-paginator-next.p-paginator-element.p-link {
  border-radius: 8px;
  height: 40px;
}
.Dashboard .p-paginator-prev.p-paginator-element.p-link {
  border-radius: 8px;
  height: 40px;
}

.Dashboard .container-toastfy {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.Dashboard .header-datatable {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
}

.Dashboard .datatable {
  font-size: 18px;
}

.Dashboard .colum {
  min-width: 10rem;
}

.p-column-filter-operator {
  background-color: #36b37e;
}

button[aria-label="Aplicar"] {
  background-color: #36b37e;
  border: none;
}

button[aria-label="Aplicar"]:hover {
  background-color: #36b39f !important;
  border: none;
}

button[aria-label="Limpar"] {
  border-color: #2684ff !important;
  color: #2684ff !important;
  border: none;
}

button[aria-label="Limpar"]:hover {
  background-color: #2684ff !important;
  color: #fff !important;
  border: none;
}

button[aria-label="Adicionar filtro"] {
  border-color: #2684ff !important;
  color: #2684ff !important;
  border: none;
}

button[aria-label="Adicionar filtro"]:hover {
  background-color: #2684ff !important;
  color: #fff !important;
  border: none;
}

@media only screen and (max-width: 1240px) {
  .Dashboard.container-full {
    display: grid;
    place-items: start;
    margin-top: 1rem;
  }
  .Dashboard .container-datatable {
    width: 100vw;
    height: 80vh;
    margin-top: 0rem;
  }
  .Dashboard .datatable {
    font-size: 12px;
  }
  .Dashboard .p-paginator.p-component.p-paginator-bottom {
    font-size: 14px;
  }
}
