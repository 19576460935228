.LPTipoAcesso.container-full {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.LPTipoAcesso .side-logo {
  display: grid;
  place-items: center;
  order: 2;
}
.LPTipoAcesso .side-cards {
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 20px;
}
.LPTipoAcesso .side-cards .logo {
  display: none;
}
.LPTipoAcesso .container-cards {
  width: 60%;
  min-width: 320px;
  max-width: 600px;
}
.LPTipoAcesso .group-cards {
  display: grid;
  align-items: center;
  gap: 15px;
}
.LPTipoAcesso .card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.6);
  transition: 300ms ease-in-out;
}
.LPTipoAcesso .icon-text {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 0px;
}

/* hover:effects */
.LPTipoAcesso .card:hover {
  transform: translateX(2px);
}

/* Responsive */
@media only screen and (max-width: 750px) {
  .LPTipoAcesso.container-full {
    display: block;
  }
  .LPTipoAcesso .side-logo {
    display: none;
  }
  .LPTipoAcesso .side-cards {
    gap: 10px;
  }
  .LPTipoAcesso .side-cards .logo {
    display: block;
  }
}
