.Header.container-header {
  display: grid;
  align-items: center;
  padding-right: 2rem;
}

.Header .bar-icon {
  display: inline;
  font-size: 24px;
  margin-right: 1rem;
}

.Header .text-header {
  font-size: 18px;
  font-weight: bold;
}

.Header .p-inputswitch {
  border: none;
}

.Header .p-inputswitch.p-component.p-focus {
  outline: none;
  border: none;
}

.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #888 !important;
}

@media only screen and (min-width: 1240px) {
  .Header .bar-icon {
    display: none;
  }
  .Header .text-header {
    font-size: 24px;
  }
}
