.School.container-full {
  height: 92vh;
  width: 100%;
  min-width: 340px;
  display: grid;
  justify-items: center;
  align-items: start;
  padding-top: 2rem;
}

.School .container-datable {
  width: 80vw;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
}

.School .header-datatable {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
}

.School .btn_registerSchool {
  height: 40px;
  min-width: 150px;
  width: 100%;
  color: white;
  font-size: 1rem;
}

.School .datatable {
  min-width: 340px;
  max-width: 100vw;
}
.School .colum {
  min-width: 10rem;
  overflow: hidden;
}

.School .p-paginator.p-component.p-paginator-bottom {
  border: none;
  /* border-radius: 3px; */
}

.School .p-paginator-next.p-paginator-element.p-link {
  border-radius: 8px;
  height: 40px;
}
.School .p-paginator-prev.p-paginator-element.p-link {
  border-radius: 8px;
  height: 40px;
}

@media only screen and (max-width: 1240px) {
  .School .container-datable {
    width: 100vw;
  }
}
