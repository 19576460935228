.Parceiros.container-full {
  display: grid;
  justify-items: center;
  align-items: start;
  width: 100%;
  height: 92vh;
  padding-top: 2rem;
}
.Parceiros .container-datatable {
  width: 80vw;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
}

.Parceiros .header-datatable {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
  padding: 1rem;
  position: relative;
}

.Parceiros .btn_registerPartner {
  height: 40px;
  min-width: 150px;
  font-size: 1rem;
  cursor: pointer;
}

.Parceiros .colum {
  min-width: 10rem;
}

.Parceiros .p-paginator.p-component.p-paginator-bottom {
  border: none;
  /* border-radius: 3px; */
}

.Parceiros .p-paginator-next.p-paginator-element.p-link {
  border-radius: 8px;
  height: 40px;
}
.Parceiros .p-paginator-prev.p-paginator-element.p-link {
  border-radius: 8px;
  height: 40px;
}

@media only screen and (max-width: 1240px) {
  .Parceiros .container-datatable {
    width: 100vw;
  }
}
@media only screen and (max-width: 450px) {
  .Parceiros .header-datatable {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 1rem;
  }
}
