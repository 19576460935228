.navLink.links {
  text-decoration: none;
  font-size: 1.5rem;
  padding: 10px;
}
.p-sidebar-header {
  display: flex;
  justify-content: space-between;
}
.p-sidebar-header button {
  align-self: start;
}
.navLink.links.active {
  border-radius: 5px;
}
.link-text {
  display: flex;
  gap: 5px;
}
.link-text i {
  height: 29px !important;
  align-self: flex-end;
}
@media only screen and (max-width: 1240px) {
  .sidebar-desk {
    display: none !important;
  }
}
